<script>

export default {
  name: "funding-request-financial-analysis",
  props: {
    fa: {
      type: Object,
      default: null
    }
  }
}
</script>

<template>
  <b-accordion>
    <b-accordion-item title="Analyse du financière" visible>
      <b-row class="gap-4">
        <div class="">
          <h5>Budget détaillé</h5>
          <div v-html="fa.detailedBudget"></div>
        </div>
        <div class="">
          <h5>Sources de revenus</h5>
          <div v-html="fa.sourceIncome"></div>
        </div>
        <div class="">
          <h5>Prévisions financières</h5>
          <div v-html="fa.financialForecast"></div>
        </div>
      </b-row>
    </b-accordion-item>
  </b-accordion>
</template>

<style scoped>

</style>