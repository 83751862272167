<script>

import {getDocumentNameFromType} from "@/helpers/functions";
import API from "@/plugins/axios-method";
// import serverUrl from "@/helpers/constant/serverUrl";

export default {
  name: "funding-request-supporting-documents",
  props: {
    supportingDocument: {
      type: Object,
      default: null,
      required: true
    }
  },
  methods: {
    getDocumentNameFromType,
    downloadFile(d){
      // window.open(serverUrl + '/documents/' +  d.uniqId, "_blank").focus();
      API.get('documents/' +  d.uniqId)
      .then(response => {

        const {url} = response.data;

        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
    }
  },
}
</script>

<template>
  <b-accordion>
    <b-accordion-item title="DOCUMENTS JUSTIFICATIFS" visible>
      <div class="table-responsive">
        <table class="table table-hover table-striped align-middle table-nowrap mb-0">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nom</th>
            <th scope="col">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(d, index) in supportingDocument.documents" :key="index">
            <th scope="row">{{index + 1}}</th>
            <td>{{ getDocumentNameFromType(d.type) }}</td>
            <td>
              <div class="hstack gap-3 fs-15">
                <a role="button" class="link-primary" @click="downloadFile(d)">
                  <i class="bx bx-download"></i>
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-accordion-item>
  </b-accordion>

</template>

<style scoped>

</style>