<script>
import API from "@/plugins/axios-method";
import {inject} from "vue";
import {getCreatedTime} from "@/helpers/functions";

export default {
  name: 'funding-request-chat',
  props: {
    fr: {
      type: Object,
      required: true,
    },
    chat: {
      type: Object,
      required: true,
    }
  },
  setup(){
    const globals = inject("globals");

    return {globals}
  },
  data() {
    return {
      isChatOpen: false,
      contact: {
        name: this.fr?.applicant[0].firstName + ' ' + this.fr?.applicant[0].lastName,
        phone: this.fr?.applicant[0].phoneNumber,
        photo: 'https://via.placeholder.com/50',
      },
      chatStatus: 'online',
      messages: [],
      newMessage: '',
      isSubmitted: false,
      selectedFile: null,
    };
  },
  mounted() {
    this.getMessagesByChat()
    this.updated()
  },
  methods: {
    getCreatedTime,
    formatDate(date) {
      const today = new Date().toISOString().split('T')[0];
      const yesterday = new Date(Date.now() - 86400000).toISOString().split('T')[0];
      if (date.split('T')[0] === today) {
        return "Aujourd'hui";
      } else if (date.split('T')[0] === yesterday) {
        return 'Hier';
      } else {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
      }
    },
    toggleChat() {
      this.isChatOpen = !this.isChatOpen;
      if (this.isChatOpen) {
        this.$nextTick(() => {
          const container = this.$refs.messagesContainer;
          container.scrollTop = container.scrollHeight;
        });
      }
    },
    getMessagesByChat(){
      API.get('/chats/' + this.chat.uniqId + '/messages')
          .then(response => {
            response.data['hydra:member'].map(mgs => {
              this.messages.push(mgs)
            })
          })
    },
    resendMessage(content){
      this.newMessage = content
      this.sendMessage()
    },
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        this.isSubmitted = true
        const messageData = {
          content: this.newMessage,
          createdAt: new Date().toISOString(),
          sender: {
            uniqId: this.globals.userId,
          },
          status: 2,
        };

        this.newMessage = '';

        this.messages.push(messageData);
        this.$nextTick(() => {
          const container = this.$refs.messagesContainer;
          container.scrollTop = container.scrollHeight;
        });

        API.post('/messages', {
          content: messageData.content,
          chat: this.chat['@id'],
        })
            .then(() => {
              this.updateLastMessageStatus();
              this.isSubmitted = false
            })
            .catch(() => {
              this.isSubmitted = false
              this.updateLastMessageStatus(0);
            })
      }
    },
    updated() {
      const container = this.$refs.messagesContainer;
      container.scrollTop = container.scrollHeight;
    },
    updateLastMessageStatus(status = 1) {
      this.messages[this.messages.length - 1].status = status
    }
  },
  computed: {
    groupedMessages() {
      const groups = [];
      this.messages.forEach((message) => {
        const date = message.createdAt;
        let group = groups.find(g => g.date?.split('T')[0] === date.split('T')[0]);
        if (!group) {
          group = { date, messages: [] };
          groups.push(group);
        }
        group.messages.push(message);
      });
      return groups;
    },
  }
};
</script>

<template>
  <div>
    <b-button @click="toggleChat" variant="primary" class="floating-button">
      <i class="bx bx-chat" />
    </b-button>

    <b-collapse v-model="isChatOpen" class="chat-widget">
      <b-container class="chat-container">
        <b-row class="chat-header align-items-center">
          <b-col cols="auto">
            <b-img :src="contact.photo" rounded="circle" width="50" height="50" />
          </b-col>
          <b-col>
            <h5 class="mb-0">{{ contact.name }}</h5>
            <small>{{ contact.phone }}</small>
          </b-col>
          <b-col cols="auto">
            <span :class="{'text-success': chatStatus === 'online', 'text-muted': chatStatus === 'offline'}">
              {{ chatStatus }}
            </span>
          </b-col>
        </b-row>
        <div class="chat-messages" ref="messagesContainer">
          <div v-for="(messageGroup, index) in groupedMessages" :key="index">
            <div class="d-flex justify-content-center py-2">
              <b-badge variant="secondary">{{ formatDate(messageGroup.date) }}</b-badge>
            </div>

            <div v-for="message in messageGroup.messages" :key="message.id" :class="message?.sender.uniqId === globals.userId ? 'message sent' : 'message received'">
              <p class="mb-1">{{ message.content }}</p>
              <small class="text-muted">
                {{ getCreatedTime(message.createdAt) }}
                <span v-if="message?.sender.uniqId === globals.userId">
                <i v-if="message.status === 1" class="ri-checkbox-circle-line text-muted"></i>
                <i v-else-if="message.status === 2" class="bx bx-time-five text-muted"></i>
                <span v-else>
                  <i class="ri-close-circle-line text-danger" title="message non envoyé"></i>
                  <i @click="resendMessage(message.content)" title="renvoyer le message" class="bx bx-revision"></i>
                </span>
              </span>
              </small>
            </div>
          </div>
        </div>

        <b-form @submit.prevent="sendMessage">
          <b-input-group>
            <b-form-textarea
                v-model="newMessage"
                placeholder="Écrire un message..."
                rows="3"
                max-rows="6"
            ></b-form-textarea>
            <b-input-group-append>
              <b-button type="submit" variant="success"><i class="bx bx-send"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
      </b-container>
    </b-collapse>
  </div>
</template>

<style scoped>
/* Styles du bouton flottant */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

/* Styles du widget de chat */
.chat-widget {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 350px;
  max-height: 500px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Styles du conteneur de chat */
.chat-container {
  max-width: 100%;
  padding: 10px;
}

/* Styles de l'en-tête du chat */
.chat-header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

/* Styles des messages */
.chat-messages {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
  padding-right: 10px;
}

/* Personnalisation de la scrollbar */
.chat-messages::-webkit-scrollbar {
  width: 8px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #798593;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background-color: #798593;
}

/* Styles des bulles de message */
.message {
  max-width: 70%;
  margin-bottom: 10px;
  padding: 8px 15px;
  border-radius: 5px;
  word-wrap: break-word;
}

.sent {
  background-color: #dcf8c6;
  align-self: flex-end;
  margin-left: auto;
}

.received {
  background-color: #ffffff;
  border: 1px solid #ccc;
  align-self: flex-start;
  margin-right: auto;
}

.text-end {
  text-align: right;
}
</style>
