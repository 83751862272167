<script>
export default {
  name: "funding-request-risk-management",
  props: {
    rm: {
      type: Object,
      default: null
    }
  }
}
</script>

<template>
  <b-accordion>
    <b-accordion-item title="Gestion des risques " visible>
      <b-row class="gap-4">
        <div class="">
          <h5>Risques identifiés associés au projet</h5>
          <div v-html="rm.risk"></div>
        </div>
        <div class="">
          <h5>Mesures prévues pour atténuer ces risque</h5>
          <div v-html="rm.measure"></div>
        </div>
        <div class="">
          <h5>Garanties proposées (le cas échéant)</h5>
          <p v-html="rm.guarantee"></p>
        </div>
      </b-row>
    </b-accordion-item>
  </b-accordion>
</template>

<style scoped>

</style>